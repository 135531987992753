import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import * as EmailValidator from "email-validator"

import theme from "../themes/theme"

import { PageLayout } from "../components/PageLayout"

import { Blurb, BlurbIcon, BlurbHeading, BlurbHeader, BlurbText } from "../components/Blurb"

import { GoChecklist, GoGraph } from "react-icons/go"
import { FaRegUser } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"
import { GiAmericanFootballBall } from "react-icons/gi"

import {
  Responsive2ColumnContainer,
  Responsive2ColumnItem,
  NineItemGrid,
  FourItemGrid,
} from "../components/Responsive2Column"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
  OneColumnSection,
  DivHideOnMobile,
  ColumnWideMobileReducedDesktop,
  RowDesktopColumnMobile,
} from "../components/SectionBlocks"

import { HeroHeadline, HeroHeadlineLanding, HeroSubHeadLanding } from "../components/HeroSection"

import SEO from "../components/seo"
import { ButtonSecondary, FormButtonSecondary } from "../components/Buttons"

const FootySeason = ({ data, location }) => {
  return (
    <PageLayout>
      <SEO
        title="Get Ready For Footy Season"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Get Footy Fit With Our 60 Day Pre-Season Training Prep"
        url={location.origin + location.pathname}
      />
      <RowSection
        rowMargin="0%"
        backgroundUrl={data?.heroImage?.childImageSharp?.fluid}
        bgX="50%"
        bgY="0%"
        style={{ minHeight: "85vh" }}>
        <OneColumnSection
          style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            justifyContent: "space-evenly",
          }}>
          <HeroHeadlineLanding>
            <h1 style={{ color: theme.colors.white, textAlign: "center", align: "center" }}>
              Get Footy Fit With Our
              <br />
              <span style={{ color: theme.colors.primaryBlueNormal }}>60 Day Pre-season</span>
              <br />
              Training Prep
            </h1>
          </HeroHeadlineLanding>
          <br />
          <HeroSubHeadLanding>
            <p
              style={{
                color: theme.colors.white,
                textAlign: "center",
              }}>
              Whether you play League, Rugby, AFL, or Soccer...
              <br />
              make sure you can play the entire season.
            </p>
          </HeroSubHeadLanding>
          <ButtonSecondary
            to="#_GetFootyFit"
            style={{ alignItems: "center", justifyContent: "center" }}>
            Start Today
          </ButtonSecondary>
        </OneColumnSection>
      </RowSection>
      <RowSection>
        <div
          style={{
            backgroundColor: theme.colors.background,
            paddingTop: "50px",
            paddingBottom: "50px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}>
          <DivHideOnMobile style={{ width: "40%", height: "100%" }}>
            {/* <div style={{ display: "inline-block" }}> */}
            <Img
              fluid={data?.footyHotStep?.childImageSharp?.fluid}
              alt="Rugby running"
              style={{
                width: "100%",
                objectPosition: "50% 50%",
              }}
            />
            {/* </div> */}
          </DivHideOnMobile>

          <ColumnWideMobileReducedDesktop
            mobileWidth="100%"
            desktopWidth="60%"
            style={{
              paddingLeft: "5%",
              paddingRight: "5%",
              display: "flex",
              flexDirection: "column",
            }}>
            <HeroHeadline>
              <h2 style={{ margin: 0, color: theme.colors.primaryBlueNormal, fontWeight: 600 }}>
                Run Further, Play Longer
              </h2>
            </HeroHeadline>
            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Conditioning</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Have the conditioning to not just play the entire game, but make it through the
                whole season.
              </BlurbText>
            </Blurb>

            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Speed</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Outpace the opposition with explosive power to run past their backs or block their
                fowards.
              </BlurbText>
            </Blurb>

            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Agility</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Sidestep or goose-step, send the other team running in circles trying to keep up
                with you.
              </BlurbText>
            </Blurb>

            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Recovery</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Turbo-charge your post-game recovery so you can train hard and play again.
              </BlurbText>
            </Blurb>

            <Blurb>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Fuel</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Fuel your body with the nutrition and energy to outperform the other team while
                staying healthy and regular.
              </BlurbText>
            </Blurb>
          </ColumnWideMobileReducedDesktop>
        </div>
      </RowSection>

      {/* Quote */}
      <RowSection
        style={{
          display: "flex",
          //   flexDirection: "row",
          backgroundColor: theme.colors.primaryBlueNormal,
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: theme.spacings.medium,
          paddingRight: theme.spacings.medium,
          justifyContent: "center",
          alignItems: "center",
        }}>
        {/* left side */}
        <TwoColumnSection
          columnLeftTop="true"
          //   marginLeft="15px"
          style={{
            alignItems: "center",
            justifyContent: "center",
            // paddingLeft: "50px",
          }}>
          <TwoColumnSectionLeft
            style={{
              //   width: "90%",
              //   paddingLeft: "10px",
              justifyContent: "center",
            }}>
            <div
              className="text-3xl"
              style={{
                alignText: "left",
                color: theme.colors.white,
                // fontSize: theme.spacings.medium,
              }}>
              <em>
                "The two things you can improve to play longer.... <strong>core strength</strong>{" "}
                and <strong>power</strong>.
                <br /> <br />
                Strength training improves both of these, with the added benefit of increased speed
                that enables you to make <strong>better decisions</strong> on the field"{" "}
              </em>
            </div>
            <div
              style={{
                display: "flex",
                paddingBottom: "20px",
                marginTop: "2rem",
              }}>
              <ButtonSecondary to="#_GetFootyFit">Start Today</ButtonSecondary>
            </div>
            {/* right side */}
          </TwoColumnSectionLeft>
          <TwoColumnSectionRight alignItems="center">
            <div
              style={{
                textAlign: "center",
                alignItems: "center",
                color: theme.colors.white,
                display: "flex",
                flexDirection: "column",
                fontSize: theme.spacings.medium,
                lineHeight: theme.spacings.medium,
              }}>
              <Img
                fixed={data?.headshotMitch?.childImageSharp?.fixed}
                style={{
                  marginBottom: "10px",
                  borderRadius: "50%",
                  boxShadow: "5px 5px 50px grey",
                  border: "1px solid " + theme.colors.primaryOrangeNormal,
                }}
              />
              <strong>Head Coach</strong>
              Mitch Jobson
            </div>
          </TwoColumnSectionRight>
        </TwoColumnSection>
      </RowSection>

      {/* Whats Included */}
      <RowSection
        style={{
          backgroundColor: theme.colors.background,
          paddingTop: theme.spacings.small,
          paddingBottom: theme.spacings.small,
          paddingLeft: theme.spacings.medium,
          // paddingRight: theme.spacings.medium,
          alignItems: "top",
        }}>
        <ColumnWideMobileReducedDesktop mobileWidth="100%" desktopWidth="60%">
          <HeroHeadline>
            <h2
              style={{
                marginTop: "0px",
                color: theme.colors.primaryBlueNormal,
                fontWeight: 600,
              }}>
              What's Included
            </h2>
          </HeroHeadline>
          <div>
            <p>
              A tailored{" "}
              <span style={{ color: theme.colors.primaryOrangeNormal }}>
                <strong>60 day</strong>
              </span>{" "}
              program based on science and proven results that will get you ready for footy season
              and wow your coach and mates
            </p>
            <p>
              Improve your strength, endurance, and agility to enable you to play at your peak
              performance all season.
            </p>
            <p>
              Reduce the risk of injury as you duck and weave, and thread your way past the opposing
              team to score.
            </p>
          </div>
          <FourItemGrid>
            <Blurb style={{ gridArea: "one" }}>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Strength Program</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Strength training will build your core, power, and conditioning. Blast past the
                opposition with explosive power, dodge their players, and last not just whole game
                but the entire season.
              </BlurbText>
            </Blurb>
            <Blurb style={{ gridArea: "two" }}>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Stamina Program</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Consistently outpace their defence to score or block their attacking runs for the
                entire game. Outpace the opposition and stay fresh.
              </BlurbText>
            </Blurb>

            <Blurb style={{ gridArea: "three" }}>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Flexibility Guide</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Sidestep or goose-step, send the other team running in circles trying to keep up
                with you as you thread your way across the field like a teflon coated god.
              </BlurbText>
            </Blurb>

            <Blurb style={{ gridArea: "four" }}>
              <BlurbHeader>
                <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                  <GoChecklist />
                </BlurbIcon>
                <BlurbHeading>Nutrition Guide</BlurbHeading>
              </BlurbHeader>
              <BlurbText>
                Fuel your body with the nutrition and energy to outperform the other team while
                staying healthy and regular.
              </BlurbText>
            </Blurb>
          </FourItemGrid>
        </ColumnWideMobileReducedDesktop>
        <DivHideOnMobile style={{ width: "40%", paddingLeft: theme.spacings.medium }}>
          <Img
            fluid={data?.deadliftGym?.childImageSharp?.fluid}
            imgStyle={{ objectFit: "cover", objectPosition: "0% 0%" }}
            style={{ width: "100%" }}
            alt="Deadlifting in the gym"
          />
        </DivHideOnMobile>
      </RowSection>

      {/* Break down of each week */}
      <RowSection
        style={{
          backgroundColor: theme.colors.background,
          paddingTop: theme.spacings.small,
          paddingBottom: theme.spacings.small,
          paddingLeft: theme.spacings.medium,
          paddingRight: theme.spacings.medium,
          //   alignItems: "top",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}>
        <OneColumnSection style={{ width: "100%", alignItems: "center" }}>
          <HeroHeadline>
            <h2
              style={{ marginTop: "0px", color: theme.colors.primaryBlueNormal, fontWeight: 600 }}>
              9 Week Program Details
            </h2>
          </HeroHeadline>
        </OneColumnSection>

        <NineItemGrid
          style={{ paddingLeft: theme.spacings.xxSmall, paddingRight: theme.spacings.xxSmall }}>
          <Blurb style={{ gridArea: "one" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Technique
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 1</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              Getting started and learning the most effective way to use the equipment will help you
              get the best results for this 60 day program.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "two" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Game Plan
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 2</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              Now that you have a baseline understanding of how to effectively and safely complete
              each exercise, it is time to see which areas you need to focus on most to ensure the
              program works to your advantage.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "three" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Strength Gains
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 3</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              You've started working out and following the{" "}
              <span style={{ color: theme.colors.primaryBlueNormal }}>
                <strong>Get Footy Fit 60 Day</strong>
              </span>{" "}
              program and you're starting to see that you are consistently lifting higher weight,
              and able to make it through the workout more easily.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "four" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Grind Time
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 4</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              It's <i>grind time</i>, time to rinse and repeat. Time to push for that extra rep and
              that extra weight. Your body should now be telling you, "I am working hard" and each
              workout should push you.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "five" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Reload & Assess
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 5</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              You've been working hard and making gains, it is time to assess how you've been
              tracking. Using progressive programming, we back off the weight to give your body time
              to recover before we start increasing the weight again.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "six" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Power Building
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 6</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              Now that you've increased your strength it is time to focus on{" "}
              <strong>explosive power</strong>. You'll find your previous max weight feels like the
              warm-up as you improve technique and massively increase lifting efficiency.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "seven" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Bar Speed
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 7</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              Everything is coming together now. You've massively increased your strength, power,
              and technique. You'll notice the bar is moving slower than before as you reach your
              true working weight.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "eight" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Final Stretch
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 8</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              This is the time to push hard. It's the final two weeks where you reach for your
              personal best. What used to feel hard is barely more than a warm up now. You're
              stronger, faster, and more explosive. Ready to run, tackle, pass, and score.
            </BlurbText>
          </Blurb>

          <Blurb style={{ gridArea: "nine" }}>
            <BlurbHeader>
              <BlurbIcon color={theme.colors.primaryOrangeNormal}>
                <GoChecklist />
              </BlurbIcon>
              <BlurbHeading>
                Game Time
                <br />
                <span style={{ fontSize: theme.spacings.small }}>Week 9</span>
              </BlurbHeading>
            </BlurbHeader>
            <BlurbText>
              It's <strong>GAME TIME</strong>. You've put in the hard work, trimmed down and muscled
              up. You're fitter and more conditioned with greater mobility, ready to play the entire
              season at peak performance.
            </BlurbText>
          </Blurb>
        </NineItemGrid>
      </RowSection>

      {/* CTA Form */}
      <RowSection
        id="_GetFootyFit"
        style={{
          backgroundColor: theme.colors.light4,
          paddingTop: theme.spacings.small,
          paddingBottom: theme.spacings.small,
          paddingLeft: theme.spacings.medium,
          paddingRight: theme.spacings.medium,
          //   alignItems: "top",
          justifyContent: "center",
        }}>
        <OneColumnSection style={{ width: "100%", alignItems: "center" }}>
          <HeroHeadline>
            <h2
              style={{
                margin: 0,
                color: theme.colors.primaryBlueNormal,
                fontWeight: 600,
              }}>
              Get Footy Fit Today!
            </h2>
          </HeroHeadline>
          <br />
          <br />
          <GetFootyFitForm />
        </OneColumnSection>
      </RowSection>
    </PageLayout>
  )
}

export default FootySeason

const MetricDivNumber = (props) => {
  return (
    <div
      style={{
        color: theme.colors.light1,
        fontFamily: theme.fonts.header2,
        fontSize: theme.spacings.medium,
      }}>
      {props.children}
    </div>
  )
}

const MetricDivTitle = (props) => {
  return (
    <div
      style={{
        color: theme.colors.light1,
        fontFamily: theme.fonts.header2,
        fontSize: theme.spacings.small,
      }}>
      {props.children}
    </div>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/rugby-league-running-tinted-1920x1080-16x9.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    socialImage: file(absolutePath: { regex: "/rugby-league-running-tinted-1920x1080-16x9.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    headshotMitch: file(
      absolutePath: { regex: "/mitch-standing-arms-crossed-blue-20200506_152253-2-778x778.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 240, height: 240, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    deadliftGym: file(absolutePath: { regex: "/deadlift-gym-1-720x720.jpg/" }) {
      childImageSharp {
        fixed(width: 720, height: 720, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    footyHotStep: file(absolutePath: { regex: "/footy-hotstep-9x16.jpg/" }) {
      childImageSharp {
        fixed(width: 800, height: 1200, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

class SportComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserSportChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userSport">
            <GiAmericanFootballBall color={theme.colors.primaryBlueNormal} fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            name="custom_5"
            id="userSport"
            aria-label="Sport"
            onChange={this.handleValueChange}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.sportBorder,
              borderRadius: "5px",
              padding: "5px",
            }}>
            <option value="0" disabled selected>
              Select Sport...
            </option>
            <option value="10">League</option>
            <option value="11">Rugby</option>
            <option value="12">Aussie Rules</option>
            <option value="13">Soccer</option>
            <option value="14">Gridiron</option>
            <option value="15">Other</option>
          </select>
        </div>
      </div>
    )
  }
}

class UserNameComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserNameChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userName">
            <FaRegUser color={theme.colors.primaryBlueNormal} fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            name="first_name"
            placeholder="First name ..."
            // value=""
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="userName"
            aria-label="First Name"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.userBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

class UserEmailComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserEmailChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userEmail">
            <AiOutlineMail color={theme.colors.primaryBlueNormal} fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="email"
            placeholder="Email address ..."
            name="email"
            id="userEmail"
            aria-label="Email Address"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.emailBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class GetFootyFitForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleUserNameChange = this.handleUserNameChange.bind(this)
    this.handleUserEmailChange = this.handleUserEmailChange.bind(this)
    this.handleUserSportChange = this.handleUserSportChange.bind(this)
    this.state = {
      validName: false,
      userBorder: "none",
      validEmail: false,
      emailBorder: "none",
      validSport: false,
      sportBorder: "none",
    }
  }

  handleUserNameChange(e) {
    if (e.length > 2) {
      this.setState({ validName: true, userBorder: "2px solid green" })
    } else {
      this.setState({ validName: false, userBorder: "2px solid red" })
    }
  }

  handleUserEmailChange(e) {
    if (EmailValidator.validate(e)) {
      this.setState({ validEmail: true, emailBorder: "2px solid green" })
    } else {
      this.setState({ validEmail: false, emailBorder: "2px solid red" })
    }
  }

  handleUserSportChange(e) {
    this.setState({ validSport: true, sportBorder: "2px solid green" })
  }

  render() {
    return (
      <div>
        <form
          method="post"
          action="https://app.kartra.com/process/add_lead/vPgDVBx2NEXc"
          target="_top"
          class="form_class_vPgDVBx2NEXc optin_form_for_video js_kartra_trackable_object"
          data-kt-type="optin"
          data-kt-value="vPgDVBx2NEXc"
          data-kt-owner="Brl61K8g"
          accept-charset="UTF-8">
          <RowDesktopColumnMobile style={{ width: "100%", justifyContent: "space-evenly" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: theme.spacings.small,
              }}>
              {/* <div style={{ paddingBottom: theme.spacings.xSmall }}>firstname label</div> */}
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <UserNameComponent
                  onUserNameChange={this.handleUserNameChange}
                  userBorder={this.state.userBorder}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: theme.spacings.small,
              }}>
              {/* <div style={{ paddingBottom: theme.spacings.xSmall }}>email label</div> */}
              <div>
                <UserEmailComponent
                  onUserEmailChange={this.handleUserEmailChange}
                  emailBorder={this.state.emailBorder}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: theme.spacings.small,
              }}>
              {/* <div style={{ paddingBottom: theme.spacings.xSmall }}>sport label</div> */}
              <div>
                <SportComponent
                  onUserSportChange={this.handleUserSportChange}
                  sportBorder={this.state.sportBorder}
                />
              </div>
            </div>
            <div
              style={{
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                marginBottom: theme.spacings.small,
              }}>
              <div>
                <FormButtonSecondary
                  type="submit"
                  enabled={this.state.validName && this.state.validEmail && this.state.validSport}
                  style={{ alignItems: "center", justifyContent: "center" }}>
                  Make the Team
                </FormButtonSecondary>
                {/* <ButtonPrimary style={{ marginTop: "0px" }}>Make the Team</ButtonPrimary> */}
              </div>
            </div>
          </RowDesktopColumnMobile>
        </form>
      </div>
    )
  }
}
